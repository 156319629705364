import { LazyLoad } from '@petconsole/pure-fe-mui';

const LazyAboutUs = LazyLoad(() => import('./AboutUs'));
const LazyFaq = LazyLoad(() => import('./Faq'));
const LazyPricing = LazyLoad(() => import('./Pricing'));
const LazyWelcome = LazyLoad(() => import('./Welcome'));

export { LazyAboutUs as AboutUs };
export { LazyFaq as Faq };
export { LazyPricing as Pricing };
export { LazyWelcome as Welcome };
