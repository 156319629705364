import { MemberAvatarMenuItem, useOurAppMemberMenuItems } from '@petconsole/pure-fe-mui';
import { webPath } from '@petconsole/pure-shared';

const useAvatarMenuItem = () => {
  const avatarMenuItems = useOurAppMemberMenuItems({
    appItems: [
      {
        menuItem: ['My Vans', webPath.myVans, true],
        condition: ([_text, path]: MemberAvatarMenuItem) => path === webPath.myShops,
        insertBefore: false,
      },
    ],
  });

  return { avatarMenuItems };
};

export default useAvatarMenuItem;
