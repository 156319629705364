import { Construction, Plumbing } from '@mui/icons-material';
import { OurVanRepairIcon } from '@petconsole/pure-fe-icon';
import { AppMenuItems } from '@petconsole/pure-fe-mui';
import { webPath } from '@petconsole/pure-shared';

export const listingMenuItems = [
  [Construction, 'Van Builders', webPath.vanBuilders],
  [OurVanRepairIcon, 'Van Repairs', webPath.vanRepairs],
  [Plumbing, 'Van Supplies', webPath.vanSupplies],
] as AppMenuItems;

export const siteMedia = {
  vanConsoleBanner: `${webPath.media}/landing/vanconsole-banner-20220907-1.png`,
  headers: {
    builders: `${webPath.media}/headers/builders-20220903-1.png`,
    credits: `${webPath.media}/headers/credits-20220623-1.png`,
    logout: `${webPath.media}/headers/logout-20220623-1.png`,
    vans: `${webPath.media}/headers/vans-20220908-1.png`,
    vanBuilders: `${webPath.media}/headers/vanBuilders-20230122-1.png`,
    vanRepairs: `${webPath.media}/headers/vanRepairs-20230122-1.png`,
    vanSupplies: `${webPath.media}/headers/vanSupplies-20230122-1.png`,
    welcome: `${webPath.media}/headers/welcome-20220623-1.png`,
  },
};
