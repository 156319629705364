import { AirportShuttle } from '@mui/icons-material';
import { Divider } from '@mui/material';
import {
  OurCommunityMenu,
  OurGeneralMenu,
  OurHomeMenuItem,
  OurListingsMenu,
  OurLogoutMenuItem,
  OurMarketplaceMenu,
  OurMyMenu,
  OurSitesMenu,
} from '@petconsole/pure-fe-mui';
import { webPath } from '@petconsole/pure-shared';
import { listingMenuItems } from '../constants';

const MemberMenuItems = () => {
  return (
    <>
      <OurHomeMenuItem />
      <OurCommunityMenu add={[[AirportShuttle, 'Vans', webPath.vans]]} />
      <OurMarketplaceMenu />
      <Divider />
      <OurListingsMenu add={listingMenuItems} />
      <OurMyMenu add={[[AirportShuttle, 'Vans', webPath.myVans]]} />
      <OurGeneralMenu />
      <OurSitesMenu exclude={[webPath.vanConsole]} />
      <Divider />
      <OurLogoutMenuItem />
    </>
  );
};

MemberMenuItems.whyDidYouRender = true;

export default MemberMenuItems;
