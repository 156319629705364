import { Route, Routes, useLocation } from 'react-router-dom';
import {
  MyOrder,
  MyOrders,
  OurNotFound,
  useCommonRoutes,
  useGeneralRoutes,
  useLoginLogoutRoutes,
  usePclRoutes,
} from '@petconsole/pure-fe-feature';
import { OurEnterSite, OurExitSite, OurExternalPath, useOurRoutes } from '@petconsole/pure-fe-mui';
import { useMyShopRoutes } from '@petconsole/pure-fe-my-shops';
import { webPath } from '@petconsole/pure-shared';
import { MyVans, Van, Vans } from '../van';
import { VanBuilder, VanBuilders } from '../vanBuilder';
import { VanRepair, VanRepairs } from '../vanRepair';
import { VanSupplies, VanSupply } from '../vanSupply';
import { LandingPage } from '../landing';
import { AboutUs, Faq, Pricing, Welcome } from '../misc';

const AppRoutes = () => {
  const { pathname } = useLocation();
  const { authRoute, routeTo } = useOurRoutes();
  const commonRoutes = useCommonRoutes();
  const generalRoutes = useGeneralRoutes();
  const loginLogoutRoutes = useLoginLogoutRoutes();
  const myShopRoutes = useMyShopRoutes();
  const pclRoutes = usePclRoutes();

  return (
    <Routes>
      <>{commonRoutes.map((commonRoute) => commonRoute)}</>
      <>{generalRoutes.map((generalRoute) => generalRoute)}</>
      <>{loginLogoutRoutes.map((loginLogoutRoute) => loginLogoutRoute)}</>
      <>{pclRoutes.map((pclRoute) => pclRoute)}</>
      {routeTo(webPath.home, LandingPage)}
      {routeTo(webPath.about, AboutUs)}
      {routeTo(webPath.faq, Faq)}
      {routeTo(webPath.pricing, Pricing)}
      {routeTo(webPath.vanBuilders, VanBuilders)}
      {routeTo(`${webPath.vanBuilders}/:id`, VanBuilder)}
      {routeTo(webPath.vanRepairs, VanRepairs)}
      {routeTo(`${webPath.vanRepairs}/:id`, VanRepair)}
      {routeTo(webPath.vanSupplies, VanSupplies)}
      {routeTo(`${webPath.vanSupplies}/:id`, VanSupply)}
      {authRoute(webPath.vans, Vans)}
      {authRoute(webPath.myVans, MyVans)}
      {authRoute(`${webPath.vans}/:id`, Van)}
      {authRoute(webPath.myOrders, MyOrders)}
      {authRoute(`${webPath.myOrders}/:id`, MyOrder)}
      <>{myShopRoutes.map((myShopRoute) => myShopRoute)}</>
      {authRoute(webPath.welcome, Welcome)}
      <Route path="/stripe-onboard/*" element={<OurExternalPath path={pathname.replace('/stripe-onboard/', '')} />} />
      {routeTo(`${webPath.site}/:site`, OurExitSite)}
      {routeTo(`${webPath.changeSite}/:id`, OurEnterSite)}
      {routeTo('*', OurNotFound)}
    </Routes>
  );
};

AppRoutes.whyDidYouRender = true;

export default AppRoutes;
